import TomSelect from "tom-select";

let settings = {
    plugins: ['dropdown_input'],
    create: false,
    createOnBlur: true
};

const provinceSelect = document.querySelector('#province_code');
const citySelect = document.querySelector('#city_code');
const districtSelect = document.querySelector('#district_code');
const villageSelect = document.querySelector('#village_code');
const postalCode = document.querySelector('#postal_code');

if (provinceSelect && !citySelect) {
    new TomSelect('#province_code', settings);
}

if (citySelect && !districtSelect) {
    let tomcities = new TomSelect('#city_code', settings);
    let tomprovince = new TomSelect('#province_code', {
        ...settings, // Spread existing settings
        onChange: function (value) {
            console.log('Province selected 1:', value);
            // Destroy only if necessary (prevents unnecessary re-initialization)
            if (tomcities) {
                tomcities.destroy();
            }

            let url = 'locations/cities/province/' + value;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    const options = data.map(item => ({
                        value: item.code,
                        text: item.name
                    }));

                    tomcities = new TomSelect('#city_code', {
                        ...settings, // Spread existing settings (including createOnBlur: false)
                        options: options
                    });
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    });
}

if (districtSelect && !villageSelect) {
    let tomdistrict = new TomSelect('#district_code', settings);
    let tomcities = new TomSelect('#city_code', {
        ...settings,
        onChange: function (value) {
            console.log('City selected:', value);
            // Destroy only if necessary (prevents unnecessary re-initialization)
            if (tomdistrict) {
                tomdistrict.destroy();
            }

            let url = 'locations/districts/city/' + value;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    const options = data.map(item => ({
                        value: item.code,
                        text: item.name
                    }));

                    tomdistrict = new TomSelect('#district_code', {
                        ...settings, // Spread existing settings (including createOnBlur: false)
                        options: options
                    });
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    });

    let tomprovince = new TomSelect('#province_code', {
        ...settings, // Spread existing settings
        onChange: function (value) {
            console.log('Province selected:', value);
            // Destroy only if necessary (prevents unnecessary re-initialization)
            if (tomcities) {
                tomcities.destroy();
            }

            let url = 'locations/cities/province/' + value;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    const options = data.map(item => ({
                        value: item.code,
                        text: item.name
                    }));

                    tomcities = new TomSelect('#city_code', {
                        ...settings, // Spread existing settings (including createOnBlur: false)
                        options: options,
                        onChange: function (value) {
                            console.log('City selected:', value);
                            // Destroy only if necessary (prevents unnecessary re-initialization)
                            if (tomdistrict) {
                                tomdistrict.destroy();
                            }

                            let url = 'locations/districts/city/' + value;
                            fetch(url)
                                .then(response => response.json())
                                .then(data => {
                                    const options = data.map(item => ({
                                        value: item.code,
                                        text: item.name
                                    }));

                                    tomdistrict = new TomSelect('#district_code', {
                                        ...settings, // Spread existing settings (including createOnBlur: false)
                                        options: options
                                    });
                                })
                                .catch(error => {
                                    console.error('Error fetching data:', error);
                                });
                        }
                    });
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    });
}

if (villageSelect) {
    let tomvillage, tomdistrict, tomcities, tomprovince;

    const resetFields = (fromLevel) => {
        if (fromLevel <= 1) {
            citySelect.value = "";
            if (tomcities) tomcities.clear();
        }
        if (fromLevel <= 2) {
            districtSelect.value = "";
            if (tomdistrict) tomdistrict.clear();
        }
        if (fromLevel <= 3) {
            villageSelect.value = "";
            if (tomvillage) tomvillage.clear();
        }
        if (postalCode) postalCode.value = "";
    };

    tomvillage = new TomSelect('#village_code', settings);

    tomdistrict = new TomSelect('#district_code', {
        ...settings,
        onChange: function (value) {
            resetFields(3);

            if (value) {
                let url = 'locations/villages/district/' + value;
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        const options = data.map(item => ({
                            value: item.code,
                            text: item.name
                        }));

                        tomvillage.clear();
                        tomvillage.clearOptions();
                        tomvillage.addOptions(options);
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            }
        }
    });

    tomcities = new TomSelect('#city_code', {
        ...settings,
        onChange: function (value) {
            resetFields(2);

            if (value) {
                let url = 'locations/districts/city/' + value;
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        const options = data.map(item => ({
                            value: item.code,
                            text: item.name
                        }));

                        tomdistrict.clear();
                        tomdistrict.clearOptions();
                        tomdistrict.addOptions(options);
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            }
        }
    });

    tomprovince = new TomSelect('#province_code', {
        ...settings,
        onChange: function (value) {
            resetFields(1);

            if (value) {
                let url = 'locations/cities/province/' + value;
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        const options = data.map(item => ({
                            value: item.code,
                            text: item.name
                        }));

                        tomcities.clear();
                        tomcities.clearOptions();
                        tomcities.addOptions(options);
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            }
        }
    });

    if (postalCode) {
        villageSelect.addEventListener('change', function (event) {
            if (event.target.value) {
                fetch('locations/villages/postal-code/' + event.target.value)
                    .then(response => response.json())
                    .then(data => {
                        postalCode.value = data.postal_code;
                    })
                    .catch(error => {
                        console.error('Error fetching postal code:', error);
                    });
            } else {
                postalCode.value = "";
            }
        });
    }
}


